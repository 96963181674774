.main-fields-wrapper
{
    display: flex;
    /* justify-content: space-between; */
    flex-direction: column;
    /* align-items: center; */
    width: 100%;
    gap: 2rem;

}.form-input-wrapper{
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    width: 100%;
    gap: 1.5rem;

}
.form-input-wrapper>div{
 
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.form-input-wrapper>div .input-label{
    min-width: 100px;
    width: auto;
    text-transform: capitalize;
  text-align: left;
}
.hr
{
    width: 100%;
height: 2px;
background: black;
margin-top: 1.5rem;
}
@media only screen and (max-width:768px) {
    .form-input-wrapper
    {
        flex-direction: column;
        align-items: flex-start;
    }
    .form-input-wrapper>div{
 
        flex-direction: column;
        gap: .5rem;
        align-items: flex-start;
    }
}