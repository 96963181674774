/* * {
    padding: 0;
    margin: 0;
  }
  
  body {
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
      url(https://images.pexels.com/photos/2166927/pexels-photo-2166927.jpeg?cs=srgb&dl=animal-aquarium-aquatic-2166927.jpg&fm=jpg)
        no-repeat center center/cover;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  } */
  
  /* a {
    font-size: 1.5rem;
    padding: 1rem 3rem;
    color: #f4f4f4;
    text-transform: uppercase;
  } */

/* Pseudo elements for icons */
/* General button style */
.btn3 {
  border: none !important;
  font-family: 'Lato' !important;
  font-size: inherit !important;
  color: inherit !important;
  background: none !important;
  cursor: pointer !important;
  padding: 25px 50px !important;
  display: inline-block !important;
  margin: 15px 30px !important;
  text-transform: uppercase !important;
  letter-spacing: 1px !important;
  font-weight: 500 !important;
  outline: none !important;
  position: relative !important;
  -webkit-transition: all 0.3s !important;
  -moz-transition: all 0.3s !important;
  transition: all 0.3s !important;
}

.btn3:after {
  content: '';
  position: absolute;
  z-index: -1;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

/* Pseudo elements for icons */
.btn3:before {
  font-family: 'FontAwesome';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  left: 100%;
  position: relative;
}
.btn3:hover {
  left: 0%;
  
}
.btn3:hover:before
{
  left: 100%;
  box-shadow: 1px 1px 7px 3px rgba(124, 125, 197, 0.4);
  -webkit-font-smoothing: antialiased;
  background: linear-gradient(
      120deg,
      transparent,
      #acabc5,
      transparent
    ) !important;
    transition: all 650ms !important;
}


/* Icon separator */
.btn-sep {
  padding: 9px 10px 9px 65px !important;
}

.btn-sep:before {
  background: rgba(0,0,0,0.15);
}

/* Button 1 */
.btn1 {
  background: #3498db !important;
  color: #fff !important;
}

.btn1:hover {
  /* background: #2980b9; */
  -webkit-font-smoothing: antialiased;
  box-shadow: 1px 1px 7px 3px rgba(124, 125, 197, 0.4);
  background: linear-gradient(
      120deg,
      transparent,
      #acabc5,
      transparent
    );
    transition: all 650ms;
}


.btn1:active {
  background: #2980b9;
  top: 2px;
}

.btn1:before {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  line-height: 1.75;
  font-size: 140%;
  width: 40px;
}
  
  
  .btn2 {
    text-decoration: none !important;
    color : #fff !important;
    position: relative;
    overflow: hidden;
    padding: 10px 15px 10px 10px !important;
    background-color: #3498db !important;
    cursor: pointer;
  }
  
  .btn2:hover {
    box-shadow: 1px 1px 7px 3px rgba(13, 81, 126, 0.4);
  }
  
  .btn2::before {
    background: linear-gradient(120deg,transparent,#0d517e,transparent);
    content: "";
    height: 100%;
    left: -100%;
    position: absolute;
    top: 0;
    transition: all .65s;
    width: 100%;
  }
  
  .btn2:hover::before {
    left: 100%;
  }
  .icon-info {
    content: "\f067";
  }
  .btn2 i {
    color:#fff;
  }
  .btn2 i::before {
    padding: 15px 20px 15px 20px !important;
    margin: -11% 0% -11% -34% !important;
    background: rgba(0,0,0,0.15);
  }
  .btn3 {
    text-decoration: none !important;
    color : #fff !important;
    position: relative;
    overflow: hidden;
    padding: 10px 15px 10px 10px !important;
    background-color: #08e8de !important;
    cursor: pointer;
    text-transform: capitalize !important;
  }
  
  .btn3:hover {
    box-shadow: 1px 1px 7px 3px rgba(8, 53, 56, 0.4);
  }
  
  .btn3::before {
    background: linear-gradient(120deg,transparent,#075555,transparent);
    content: "";
    height: 100%;
    left: -100%;
    position: absolute;
    top: 0;
    transition: all .65s;
    width: 100%;
  }
  
  .btn3:hover::before {
    left: 100%;
  }
  .icon-info {
    content: "\f067";
  }
  .btn3 i {
    color:#fff;
  }
  .btn3 i::before {
    padding: 15px 20px 15px 20px !important;
    margin: -11% 0% -11% -34% !important;
    background: rgba(0,0,0,0.15);
  }
  .imagedata {
    color:#fff;
    /* width: 1.5rem; */
    padding: 15px 7px 15px 8px !important;
    margin: -11% 2% -11% -6% !important;
    background: rgba(0,0,0,0.15)
  }
  .btn2.imagedata::before {
    padding: 15px 20px 15px 20px !important;
    margin: -11% 5% -11% -34% !important;
    background: rgba(0,0,0,0.15) !important;
  }
  .imagedata img{
    width: 1.5rem;
    color: #fff;
  }
.App {
  font-family: sans-serif;
  text-align: center;
}
.slider-parent{
  position:relative;
}
.buble{
  position:absolute;
}
.rdt_Table .calculatedDate{
  display: block;
  margin-top: 4.5rem;
  margin-left: -7rem !important;
}
.btn5 {
  display: inline-flex; /* Display as inline-flex to keep img and text on same line */
  align-items: center; /* Align img and text vertically */
  text-decoration: none;
  color: #fff !important;
  position: relative;
  overflow: hidden;
  padding: 10px 15px 10px 10px !important;
  background-color: #3498db !important;
  cursor: pointer;
}

.btn5:hover {
  box-shadow: 1px 1px 7px 3px rgba(13, 81, 126, 0.4);
}

.btn5:before {
  background: linear-gradient(120deg, transparent, #0d517e, transparent);
  content: "";
  height: 100%;
  left: -100%;
  position: absolute;
  top: 0;
  transition: all .65s;
  width: 100%;
}

.btn5:hover:before {
  left: 100%;
}

.btn5 img {
  height: 100%; /* Make sure the img fills the full height of the button */
  margin-right: 10px; /* Add some spacing between img and text */
}

.btn5 span {
  display: inline-block; /* Display text as inline-block to control width */
  margin-right: 10px; /* Add some spacing between img and text */
}

.icon-info {
  content: "\f067";
}

.btn5 span {
  color: #fff;
  font-weight: bold;
}

.btn5 span::before {
  padding: 15px 20px 15px 20px !important;
  background: rgba(0, 0, 0, 0.15);
}

.btn5:hover span::before {
  background: rgba(255, 255, 255, 0.15); /* Change the background on hover */
}
