.main-container
{
    position: fixed;
    inset: 0;
    background-color: #292a7385;
    z-index: 9999;display: flex;
    justify-content: center;
    align-items: center;
}
.spinner {
    /* Spinner size and color */
    width: 3rem;
    height: 3rem;
    border-top-color: #444;
    border-left-color: #444;
  
    /* Additional spinner styles */
    animation: spinner 400ms linear infinite;
    border-bottom-color: transparent;
    border-right-color: transparent;
    border-style: solid;
    border-width: 4px;
    border-radius: 50%;  
    box-sizing: border-box;
    display: inline-block;
    vertical-align: middle;
  }
  
  /* Animation styles */
  @keyframes spinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  
  /* Optional — create your own variations! */
  .spinner-large {
    width: 5rem;
    height: 5rem;
    border-width: 6px;
  }
  
  .spinner-slow {
    animation: spinner 1s linear infinite;
  }
  
  .spinner-blue {
    border-top-color: #09d;
    border-left-color: #09d;
  }





  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
  }
  
  .loader {
    position: relative;
    width: 4rem;
    height: 4rem;
    perspective: 800px;
  }
  
  .cube1,
  .cube2 {
    position: absolute;
    width: 12rem;
    height: 12rem;
    border-radius: 50%;
    border-color: #007bff;
    animation: cube-animation 1.8s infinite ease-in-out;
  }
  
  .cube1 {
    animation-delay: 0.3s;
    transform: scale(1.2);
  }
  
  .cube2 {
    animation-delay: 0.9s;
    transform: scale(0.6);
  }
  
  @keyframes cube-animation {
    0%,
    100% {
      transform: translateZ(0) rotateY(0) rotateX(0);
    }
    25% {
      transform: translateZ(1rem) rotateY(90deg) rotateX(0);
    }
    50% {
      transform: translateZ(1rem) rotateY(90deg) rotateX(90deg);
    }
    75% {
      transform: translateZ(1rem) rotateY(0) rotateX(90deg);
    }
  }
  .pre-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 9999999999999999999;
    display: flex;
    align-items: center;
    justify-content: center;
    


}

.logo {
    display: flex;
    flex-direction: column;
    align-items: center;

    justify-content: center;
    /* / animation: boxShadow 3s linear infinite; / */
}

.logo-image img
{
    width: 150px;
    height: auto;
    opacity: 0;
    animation: fadeIn 3s linear infinite;
}

/* .logo-text-image span {
    font-weight: bold;

} */

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes boxShadow {
    0% {
        box-shadow: 0 0 0 0 rgba(238, 1, 76, 0), 0 0 0 0 rgba(254, 204, 54, 0);
    }

    50% {
        box-shadow: 0 0 20px 10px rgba(238, 1, 76, 0.5), 0 0 20px 10px rgba(254, 204, 54, 0.5);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(238, 1, 76, 0), 0 0 0 0 rgba(254, 204, 54, 0);
    }
}