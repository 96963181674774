.unit-wrapper {
  max-width: 700px;
}

.actions-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
.active-propertied
{
    position: relative;
}

.active-propertied::before
{
    position:absolute;
    top:45%;
    left:-40px;
    width: 30px;
    height: 5px;
    content: '';
    border-radius: 20px;
    background-color: rgb(38, 41, 43);
}
.actions-wrapper p {
  margin-top: unset;
  margin-bottom: unset;
}
.upgrade-side {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 70px;
}
.upgrade-btn {
  background-color: rgb(38, 41, 43);
  color: white;
  margin: 0 1rem;
  padding: 0.5rem 1rem;
  border-radius: 0.2rem;
  font-weight: 400;
  text-transform: uppercase;
}

.upgrade-icon {
  color: green !important;
}

.total-issues {
  margin-left: 2rem;
}

.filter-side
{
    display: flex;
    justify-content:center;
    align-items: center;
    gap:0rem 1rem;
    margin-left: 20px;
}

.dowloader
{
    width: 60px;
    height: 60px;
}

.dowloader img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}
.order-table-area {
  margin-top: 0px !important;
  box-shadow: none !important;
}

.first-td
{
    position:relative
}

.first-td .checkbtn
{
    position:absolute;
    content: "";
    top:50%;
    transform: translate(-50%,-50%);
    left:40px;
}
