.fc-col-header-cell-cushion,.fc-daygrid-day-number
{
    color:green !important;
    text-decoration: none;
}
.fc-button{
    background-color: green !important;
    border: 1px solid rgb(16, 68, 16) !important;
}
.fc-event
{
    background-color: green !important;
    border: 1px solid green !important;
    color: white !important;
}
.fc-list-event:hover td
{
    background-color: rgb(16, 68, 16)!important;
    /* border: 1px solid rgb(9, 36, 9) !important; */
    color: white !important;
}
.fc-toolbar-title
{
    color: green !important;
}
.fc-list-day-text,.fc-list-day-side-text
{
    text-decoration: none !important;
    color: green !important;
}
.fc-list-day-text,.fc-list-day-side-text:hover
{
    text-decoration: none !important;
    color: green !important;
}