.dataTables_wrapper .dt-buttons button {
    background: transparent !important;
    color: black;
    border: none;
  }
  
  .dataTables_wrapper .dt-buttons button:hover {
    background: transparent !important;
  }

  .dataTables_wrapper .dt-buttons button .dt-button .buttons-csv .buttons-html5{
    background: url('../Images/csv.png');
    color: black;
    border: none;
  }
  
