@tailwind base;
@tailwind components;
@tailwind utilities;

.cke_browser_webkit {
  border: 1px solid #e5e7eb;
  border-radius: 5px;
}

.cke_button, .cke_combo {
  border-radius: 5px;
}

.cke_inner span {
	background-color: #e5e7eb;
}
